var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search",style:({ 
            'padding-top': _vm.facade.high_style/2 + 'px' ,
            'padding-bottom': _vm.facade.high_style/2 + 'px' ,
            'background-color':_vm.facade.background_color,
        })},[_c('div',{staticClass:"search-content",class:{'angle':_vm.facade.border_style==2},style:({ 
            textAlign: _vm.facade.text_align ,
            color: _vm.facade.text_color ,
            'background-color':_vm.facade.border_color,
        })},[_c('i',{staticClass:"le-icon le-icon-search",style:({ 
            color: _vm.facade.icon_color ,
        })}),_c('span',[_vm._v(_vm._s(_vm.content.text||'请输入关键字搜索'))])])])}
var staticRenderFns = []

export { render, staticRenderFns }