/*
 * @Author: qinuoyun
 * @Date:   2021-04-20 16:04:27
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-06-29 10:53:47
 */
import Layout from './layout.vue'

export default {
    version: '1.0.0',
    path: '/plugins/details',
    component: Layout,
    hidden: true,
    name: 'task',
    meta: {
        title: '积分商城',
        parentMenu: '/plugins/plugins',
    },
    children: [{
        path: '/plugins/task/index',
        component: () => import('@/plugins/task/index'),

        meta: {
            title: '积分商品',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins'
        }
    }, {
        path: '/plugins/task/add',
        component: () => import('@/plugins/task/add'),
        hidden: true,
        meta: {
            title: '添加商品',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins',
            extendMenu: "/plugins/task/index"
        }
    }, {
        path: '/plugins/task/edit',
        component: () => import('@/plugins/task/edit'),
        hidden: true,
        meta: {
            title: '编辑商品',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins',
            extendMenu: "/plugins/task/index"
        }
    }, {
        path: '/plugins/task/order',
        component: () => import('@/plugins/task/order'),
        meta: {
            title: '兑换记录',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins'
        }
    }, {
        path: '/plugins/task/record',
        component: () => import('@/plugins/task/record'),

        meta: {
            title: '积分收支',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins'
        }
    }, {
        path: '/plugins/task/setting',
        component: () => import('@/plugins/task/setting'),
        meta: {
            title: '基础设置',
            keepAlive: false,
            activeMenu: '/plugins/tools',
            parentMenu: '/plugins/plugins'
        }
    }]
}