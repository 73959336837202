var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goodstask",style:({
              backgroundColor: _vm.facade.background_color
          })},[(_vm.is_emtpy)?_c('div',{staticClass:"control-task"},[_c('div',{staticClass:"control-task_header",style:({backgroundColor:_vm.facade.title_background,color:_vm.facade.title_color})},[_vm._v(" 签到送积分 ")]),_c('div',{staticClass:"control-task_body",style:({backgroundColor:_vm.facade.content_background})},[_c('img',{staticClass:"control-task_icon",attrs:{"src":_vm.getIconUrl}}),_c('div',{staticClass:"control-task_title",style:({color:_vm.facade.content_color})},[_vm._v("每日签到获得10积分"),_c('br'),_vm._v("连续签到5天，获得额外50积分")]),_c('div',{staticClass:"control-task_button",style:({backgroundColor:_vm.facade.button_background,color:_vm.facade.button_color})},[_vm._v("去攒积分")])])]):_c('div',{staticClass:"control-task"},[_c('div',{staticClass:"control-task_header",style:({backgroundColor:_vm.facade.title_background,color:_vm.facade.title_color})},[_vm._v(" "+_vm._s(_vm.content.task.name)+"送积分 ")]),_c('div',{staticClass:"control-task_body",style:({backgroundColor:_vm.facade.content_background})},[_c('img',{staticClass:"control-task_icon",attrs:{"src":_vm.getIconUrl}}),_c('div',{staticClass:"control-task_title",style:({color:_vm.facade.content_color})},[_vm._v(_vm._s(_vm.getRemark(_vm.content.task)))]),_c('div',{staticClass:"control-task_button",style:({backgroundColor:_vm.facade.button_background,color:_vm.facade.button_color})},[_vm._v(_vm._s(_vm.content.title || '去攒积分'))])])]),_c('div',{staticClass:"goods-layout",class:{
                      larger:_vm.facade.list_style==1,
                      small:_vm.facade.list_style==2,
                      list:_vm.facade.list_style==3,
                      swipe:_vm.facade.list_style==4
          },style:({
              paddingLeft: _vm.facade.padding + 'px' ,
              paddingRight:_vm.facade.padding + 'px' ,
              marginLeft: -_vm.facade.margin/2 + 'px' ,
              marginRight:-_vm.facade.margin/2 + 'px' ,
          })},[_vm._l((_vm.goodsData),function(item,index){return _c('div',{key:index,staticClass:"goods-wrapper",class:{
                      white:_vm.facade.card_style==1,
                      card:_vm.facade.card_style==2,
                      stroke:_vm.facade.card_style==3,
                      lucency:_vm.facade.card_style==4,
                      chamfer:_vm.facade.chamfer_style
          }},[_c('div',{staticClass:"goods-item",style:({
              margin: _vm.facade.margin/2 + 'px' ,
          })},[_c('div',{staticClass:"goods-item-photo"},[_c('div',{staticClass:"goods-item-image"},[_c('div',{staticClass:"goods-item-cover"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.getGoodsCover(item.slideshow),"fit":"cover"}})],1)])]),(_vm.is_hide)?_c('div',{staticClass:"goods-item-detail"},[(_vm.content.is_title)?_c('div',{staticClass:"goods-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('div',{staticClass:"goods-item-info"},[_c('div',{staticClass:"goods-item-price"},[(_vm.content.is_price)?_c('div',{staticClass:"goods-item-price__box"},[_c('i',{staticClass:"goods-item-price__icon le-icon le-icon-jifen"}),_c('span',{staticClass:"goods-item-price__num"},[_vm._v(_vm._s(item.task.task_number)+"+")]),_c('span',{staticClass:"goods-item-price__tag"},[_vm._v("¥")]),_c('span',{staticClass:"goods-item-price__val"},[_vm._v(_vm._s(item.task.task_price))])]):_vm._e()]),(_vm.content.is_button)?_c('div',{staticClass:"goods-item-button",class:{
                              'minibnt':_vm.facade.list_style==1,
                              'bigbnt':_vm.facade.list_style==2,
                              'minibnt':_vm.facade.list_style==3,
                              'bigbnt':_vm.facade.list_style==4
                          }},[_vm._v(" 兑换 ")]):_vm._e()])]):_vm._e()])])}),_c('div',{staticClass:"clear"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }