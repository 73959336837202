import { render, staticRenderFns } from "./manualBinding.vue?vue&type=template&id=928bcf08&scoped=true&"
import script from "./manualBinding.vue?vue&type=script&lang=js&"
export * from "./manualBinding.vue?vue&type=script&lang=js&"
import style0 from "./manualBinding.vue?vue&type=style&index=0&id=928bcf08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "928bcf08",
  null
  
)

export default component.exports