/*
 * @Author: qinuoyun
 * @Date:   2020-08-31 15:42:11
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-02-11 14:25:59
 */
import attribute from './attribute'
import element from './element'

export default {
    attribute,
    element
}