var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation",style:({
                    'background-color': _vm.facade.background_color
            })},[(_vm.content.style==2)?_c('div',{staticClass:"navigation-text",class:{'one':_vm.facade.nav_style==1,'two':_vm.facade.nav_style==2}},_vm._l((_vm.navigationData),function(item,index){return _c('div',{key:index,staticClass:"navigation-text-item",style:({width:_vm.width})},[_c('span',{staticClass:"navigation-text-item__title",style:({
                    color: _vm.facade.text_color
            })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0):_vm._e(),(_vm.content.style==1)?_c('div',{staticClass:"navigation-image",class:{'one':_vm.facade.nav_style==1,'two':_vm.facade.nav_style==2}},_vm._l((_vm.navigationData),function(item,index){return _c('div',{key:index,staticClass:"navigation-image-item",style:({width:_vm.width})},[_c('div',{staticClass:"navigation-image-item__cover",style:({
                                      'background-image': 'url('+_vm.getCover(item.url)+')'
                                })}),_c('div',{staticClass:"navigation-image-item__title",style:({
                    color: _vm.facade.text_color
            })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0):_vm._e(),(_vm.facade.nav_style==2)?_c('div',{staticClass:"navigation-indicator"},[_c('pointer',{attrs:{"margin":-10,"color":_vm.facade.active_color,"type":_vm.facade.indicator_style,"align":"center"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }