var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods-selet__table"},[(_vm.self.type == 'radio')?_c('el-radio-group',{on:{"change":_vm.handleChange},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},[_c('el-table',{ref:"multipleTable",attrs:{"data":_vm.list}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"goods-selet__table-item"},[_c('div',{staticClass:"goods-selet__table-radio"},[_c('el-radio',{attrs:{"label":scope.row.roomid}},[_c('span')])],1),_c('div',[_vm._v(" "+_vm._s(_vm._f("getTime")(scope.row.start_time))+" - "),_c('br'),_vm._v(" "+_vm._s(_vm._f("getTime")(scope.row.end_time))+" ")])])]}}],null,false,3104577989)},[_c('template',{slot:"header"},[_c('span',{staticStyle:{"padding-left":"30px"}},[_vm._v("直播时间")])])],2),_c('el-table-column',{attrs:{"label":"直播间信息","width":"416"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('div',{staticClass:"flex"},[_c('el-image',{staticClass:"le-live--image",attrs:{"src":scope.row.cover_img_base64}}),_c('div',[_c('div',[_vm._v(_vm._s(scope.row.name))]),_c('div',{staticClass:"le-assist"},[_vm._v(" 房间号："+_vm._s(scope.row.roomid)),_c('br'),_vm._v(" 主播："+_vm._s(scope.row.anchor_name)+" ")])])],1)}}],null,false,1697650462)}),_c('el-table-column',{attrs:{"label":"直播状态","width":"156px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"effect":"plain","size":"medium","type":scope.row.live_status === 101
                ? 'success'
                : scope.row.live_status === 102
                ? 'warning'
                : scope.row.live_status === 103 || scope.row.live_status === 107
                ? 'info'
                : scope.row.live_status === 104 || scope.row.live_status === 106
                ? 'danger'
                : ''}},[_vm._v(" "+_vm._s(scope.row.live_status === 101 ? '直播中' : scope.row.live_status === 102 ? '未开始' : scope.row.live_status === 103 ? '已结束' : scope.row.live_status === 104 ? '禁播' : scope.row.live_status === 105 ? '暂停' : scope.row.live_status === 106 ? '异常' : scope.row.live_status === 107 ? '已过期' : '')+" ")])]}}],null,false,21685689)})],1)],1):_vm._e(),_c('div',{staticClass:"goods-selet__goodspage"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next,jumper","background":"","page-size":5,"current-page":_vm.page.current,"page-count":_vm.page.count},on:{"current-change":_vm.handleSizeChange,"update:currentPage":function($event){return _vm.$set(_vm.page, "current", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "current", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }