var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pointer",style:({
    marginBottom:_vm.margin+'px',
    marginLeft:_vm.margin/2+'px',
})},[(_vm.type<3)?_c('div',{staticClass:"pointer-content",class:{
    circle:_vm.type==1,
    strip:_vm.type==2,
},style:({
    textAlign:_vm.align,
})},[_c('span',{staticClass:"pointer-item active",style:({
            'background-color':_vm.color
        })}),_c('span',{staticClass:"pointer-item"}),_c('span',{staticClass:"pointer-item"})]):_vm._e(),(_vm.type==3)?_c('div',{staticClass:"pointer-text",style:({
        textAlign:_vm.align
    })},[_c('span',[_vm._v("1/3")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }