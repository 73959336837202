/*
 * @Author: qinuoyun
 * @Date:   2020-08-31 15:42:11
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-02-11 22:12:16
 */
import attribute from './attribute'
import element from './element'
import widget from './widget'

export default {
    attribute,
    element,
    widget
}