/*
 * @Author: qinuoyun
 * @Date:   2021-04-29 15:42:11
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-04-29 16:01:27
 */

import menus from './menus.vue';

export default menus;
