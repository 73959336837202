var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"separate",style:({
    paddingTop: _vm.facade.margin+'px',
    paddingBottom: _vm.facade.margin+'px'
})},[_c('div',{style:({ 
            borderTopWidth: '1px' ,
            borderTopStyle:_vm.content.style,
            borderTopColor: _vm.facade.fill_color
        })})])}
var staticRenderFns = []

export { render, staticRenderFns }