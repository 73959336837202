import { render, staticRenderFns } from "./element.vue?vue&type=template&id=e47f7aec&scoped=true&"
import script from "./element.vue?vue&type=script&lang=js&"
export * from "./element.vue?vue&type=script&lang=js&"
import style0 from "./element.vue?vue&type=style&index=0&id=e47f7aec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e47f7aec",
  null
  
)

export default component.exports