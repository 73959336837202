var render, staticRenderFns
import script from "./popconfirm.vue?vue&type=script&lang=js&"
export * from "./popconfirm.vue?vue&type=script&lang=js&"
import style0 from "./popconfirm.vue?vue&type=style&index=0&id=e8b36e8a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8b36e8a",
  null
  
)

export default component.exports