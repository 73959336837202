var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title",style:({ 
            'padding-top': _vm.facade.margin + 'px',
            'padding-bottom': _vm.facade.margin + 'px',
            'backgroundColor': _vm.facade.background_color
        })},[(_vm.content.style == 1)?_c('div',{staticClass:"title-style1",style:({color: _vm.facade.title_color})},[_c('div',{staticClass:"title-content"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")])]):_vm._e(),(_vm.content.style == 2)?_c('div',{staticClass:"title-style2",style:({color: _vm.facade.title_color})},[_c('div',{staticClass:"title-content"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{staticClass:"title-decorate"},[_vm._v("◇")])]):_vm._e(),(_vm.content.style == 3)?_c('div',{staticClass:"title-style3"},[_c('div',{staticClass:"title-content",style:({
            'color': _vm.facade.title_color,
            'font-size': _vm.facade.title_font_size+'px'
        })},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(_vm.content.is_more)?_c('div',{staticClass:"title-more",style:({
            'color': _vm.facade.more_color
        })},[_vm._v(" "+_vm._s(_vm.content.moretitle)+" "),_c('i',{staticClass:"le-icon le-icon-arrow-right"})]):_vm._e(),_c('div',{staticClass:"title-subtitle",style:({
            'color': _vm.facade.subtitle_color,
            'font-size': _vm.facade.subtitle_font_size+'px'
        })},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }