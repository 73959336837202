var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods",style:({
            backgroundColor: _vm.facade.background_color
        })},[_c('div',{staticClass:"goods-layout",class:{
                    larger:_vm.facade.list_style==1,
                    small:_vm.facade.list_style==2,
                    list:_vm.facade.list_style==3,
                    swipe:_vm.facade.list_style==4
        },style:({
            paddingLeft: _vm.facade.padding + 'px' ,
            paddingRight:_vm.facade.padding + 'px' ,
            marginLeft: -_vm.facade.margin/2 + 'px' ,
            marginRight:-_vm.facade.margin/2 + 'px' ,
        })},[_vm._l((_vm.goodsData),function(item,index){return _c('div',{key:index,staticClass:"goods-wrapper",class:{
                    white:_vm.facade.card_style==1,
                    card:_vm.facade.card_style==2,
                    stroke:_vm.facade.card_style==3,
                    lucency:_vm.facade.card_style==4,
                    chamfer:_vm.facade.chamfer_style
        }},[_c('div',{staticClass:"goods-item",style:({
            margin: _vm.facade.margin/2 + 'px' ,
        })},[_c('div',{staticClass:"goods-item-photo"},[_c('div',{staticClass:"goods-item-image"},[_c('div',{staticClass:"goods-item-cover"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.getGoodsCover(item.slideshow),"fit":"cover"}})],1)])]),(_vm.is_hide)?_c('div',{staticClass:"goods-item-detail"},[(_vm.content.is_title)?_c('div',{staticClass:"goods-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),_c('div',{staticClass:"goods-item-info"},[_c('div',{staticClass:"goods-item-price"},[(_vm.content.is_price)?_c('div',[_c('span',{staticClass:"goods-item-price__tag"},[_vm._v("¥")]),_c('span',{staticClass:"goods-item-price__val"},[_vm._v(_vm._s(item.price))])]):_vm._e()]),(_vm.content.is_button)?_c('div',{staticClass:"goods-item-button"},[_c('i',{staticClass:"le-icon le-icon-nav_shoppingcart_normal"})]):_vm._e()])]):_vm._e()])])}),_c('div',{staticClass:"clear"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }